import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { auth } from '../services/api';
import OtpInput from "react-otp-input";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

function Register() {
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [formData, setFormData] = useState({
    email: '',
    otp: '',
    name: '',
    mobile: '',
    companyName: '',
    userType: 'Individual',
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
    },
  });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendAttempts, setResendAttempts] = useState(0); // New state for resend attempts
  const [resendTimer, setResendTimer] = useState(30);
  const navigate = useNavigate();


  useEffect(() => {
    let timer;
    if (resendTimer > 0 && step === 2) {
      timer = setTimeout(() => setResendTimer((prev) => prev - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendTimer, step]);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);

    const isValid = validateAllFields(['email']); // Validate only the email field
    if (!isValid) {
      toast.error('Please enter your email.');
      setLoading(false);
      return;
    }

    try {
      const response = await auth.registerEmail({ email_id: formData.email });
      setIsOtpSent(true);
      toast.success('OTP sent to your email.');
      setStep(2);
    } catch (error) {
      if (error.response?.status === 501) {
        toast.error('User already exists. Please login.');
        navigate('/');
      } else {
        toast.error('Failed to send OTP');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    if (resendAttempts >= 2) {
      toast.error('Resend limit reached.');
      return;
    }

    setLoading(true);
    try {
      const response = await auth.registerEmail({ email_id: formData.email });
      setResendAttempts((prev) => prev + 1);
      setResendTimer(30); // Reset timer on resend
      toast.success('OTP resent to your email.');
    } catch (error) {
      toast.error('Failed to resend OTP');
    } finally {
      setLoading(false);
    }
  };
//   const handleResendOtp = async () => {
//     if (resendAttempts >= 2) {
//       toast.error('Resend limit reached.');
//       return;
//     }

//     setLoading(true);
//     try {
//       const response = await auth.registerEmail({ email_id: formData.email });
//       setResendAttempts((prev) => prev + 1); // Increment resend attempts
//       toast.success('OTP resent to your email.');
//     } catch (error) {
//       toast.error('Failed to resend OTP');
//     } finally {
//       setLoading(false);
//     }
//   };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!errors.otp) {
      try {
        await auth.registerOTP({ email_id: formData.email, otp: formData.otp });
        toast.success('OTP verified. Continue with registration.');
        setStep(3);
      } catch (error) {
        toast.error('Invalid OTP');
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('Invalid OTP');
      setLoading(false);
    }
  };

  const handleCompleteRegistration = async (e) => {
    e.preventDefault();
    setLoading(true);

    const isValid = validateAllFields(['name', 'mobile', 'address.street', 'address.city', 'address.state', 'address.zip']);
    if (!isValid) {
      toast.error('Please fill all required fields.');
      setLoading(false);
      return;
    }

    try {
      await auth.register(formData);
      toast.success('Registration completed successfully.');
      navigate('/');
    } catch (error) {
        console.log(error);
      toast.error('Failed to complete registration.');
    } finally {
      setLoading(false);
    }
  };

  const validateField = (fieldName, value) => {
    let error = '';

    if (fieldName === 'email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      error = 'Invalid email address';
    }

    if (fieldName === 'mobile' && !/^\d{10}$/.test(value)) {
      error = 'Invalid mobile number';
    }
    if (fieldName === 'address.zip') {
        if (!/^[1-9][0-9]{5}$/.test(value)) {
          error = 'Invalid ZIP code for India';
        }
      }
    if (['address.street', 'address.city', 'address.state', 'address.zip'].includes(fieldName) && value.trim() === '') {
      error = 'This field is required';
    }

    return error;
  };

  const validateAllFields = (fields) => {
    let isValid = true;
    const newErrors = {};

    fields.forEach((field) => {
      const value = field.includes('.')
        ? field.split('.').reduce((acc, key) => acc[key], formData)
        : formData[field];

      const error = validateField(field, value);
      if (error) {
        isValid = false;
        newErrors[field] = error;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e, isNested = false) => {
    if (typeof e === "string") {
      // Handle OTP input
      setFormData((prevData) => ({ ...prevData, otp: e }));
      setErrors((prevErrors) => ({ ...prevErrors, otp: validateField("otp", e) }));
      return;
    }
  
    const { name, value } = isNested ? { name: e.target.name, value: e.target.value } : e.target;
  
    setFormData((prevData) => {
      if (name.includes(".")) {
        const keys = name.split(".");
        const updatedData = { ...prevData };
        keys.reduce((acc, key, index) => {
          if (index === keys.length - 1) {
            acc[key] = value;
          }
          return acc[key];
        }, updatedData);
        return updatedData;
      } else {
        return { ...prevData, [name]: value };
      }
    });
  
    setErrors((prevErrors) => ({ ...prevErrors, [name]: validateField(name, value) }));
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
  };
  
//   const handleChange = (e, isNested = false) => {
//     const { name, value } = isNested ? { name: e.target.name, value: e.target.value } : e.target;

//     setFormData((prevData) => {
//       if (name.includes('.')) {
//         const keys = name.split('.');
//         const updatedData = { ...prevData };
//         keys.reduce((acc, key, index) => {
//           if (index === keys.length - 1) {
//             acc[key] = value;
//           }
//           return acc[key];
//         }, updatedData);
//         return updatedData;
//       } else {
//         return { ...prevData, [name]: value };
//       }
//     });

//     setErrors((prevErrors) => ({ ...prevErrors, [name]: validateField(name, value) }));
//     setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
//   };

  return (
    <Container maxWidth="xs">
      <Box textAlign="center" mt={8} mb={4}>
        <Typography variant="h4" component="h2" gutterBottom>
          {step === 1 && 'Enter your email'}
          {step === 2 && 'Verify OTP'}
          {step === 3 && 'Complete Registration'}
        </Typography>
      </Box>

      {step === 1 && (
        <form onSubmit={handleSendOtp}>
          <Box mb={2}>
            <ToggleButtonGroup
              value={formData.userType}
              exclusive
              onChange={(e, value) => setFormData({ ...formData, userType: value })}
              aria-label="User Type"
              fullWidth
            >
              <ToggleButton value="Individual">Individual</ToggleButton>
              <ToggleButton value="Company">Company</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box mb={2}>
            <TextField
              id="email"
              label="Email Address"
              type="email"
              name="email"
              required
              fullWidth
              variant="outlined"
              value={formData.email}
              onChange={(e) => handleChange(e)}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {loading ? 'Sending OTP...' : 'Send OTP'}
          </Button>
        </form>
      )}

      {step === 2 && (
        <form onSubmit={handleVerifyOtp}>
          <Box mb={2}>
            <OtpInput
              value={formData.otp || ""}
              onChange={(otp) => handleChange(otp, true)}
              numInputs={6}
              isInputNum
              renderSeparator={<span>-</span>}
            renderInput={(props) => (
            <input
                {...props}
                onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Sanitize input
                }}
            />
            )}
              containerStyle={{ justifyContent: "center" }}
              inputStyle={{
                width: "40px",
                height: "40px",
                margin: "5px",
                fontSize: "18px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                textAlign: "center",
              }}
            />
          </Box>
          <Box mt={2} display={'flex'} justifyContent="right">
            {/* <Typography variant="body2" align="center">
              Didn't receive the OTP? You can resend it up to 2 times.
            </Typography> */}
            <Button
              variant="text"
              color="primary"
              onClick={handleResendOtp}
              disabled={resendTimer > 0 || resendAttempts >= 2 || loading}
            >
              {resendTimer > 0 ? `Resend OTP in ${resendTimer}s` : 'Resend OTP'}
            </Button>
          </Box>
          {resendAttempts >= 2 && (
            <Typography color="error" align="center">
              Resend limit reached. Please try again later.
            </Typography>
          )}
           <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {loading ? 'Verifying...' : 'Verify OTP'}
          </Button>
          {/* <Box mt={2} display={resendAttempts < 2 ? 'flex' : 'block'} justifyContent="right"> */}
            {/* <Typography variant="body2" align="center">
              Didn't receive the OTP?
            </Typography> */}
            {/* <Button
              variant="text"
              color="primary"
              onClick={handleResendOtp}
              disabled={resendAttempts >= 2 || loading}
            >
              Resend OTP
            </Button>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {loading ? 'Verifying...' : 'Verify OTP'}
          </Button>
          
          {resendAttempts >= 2 && (
            <Typography color="error" align="center">
              Resend limit reached. Please try again later.
            </Typography>
          )} */}
        </form>
      )}

      {step === 3 && (
        <form onSubmit={handleCompleteRegistration}>
          <Box mb={2}>
            <TextField
              id="name"
              label="Full Name"
              type="text"
              name="name"
              required
              fullWidth
              variant="outlined"
              value={formData.name}
              onChange={(e) => handleChange(e)}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Box>
          <Box mb={2}>
            <TextField
              id="mobile"
              label="Mobile Number"
              type="text"
              name="mobile"
              required
              fullWidth
              variant="outlined"
              value={formData.mobile}
              onChange={(e) => handleChange(e)}
              error={!!errors.mobile}
              helperText={errors.mobile}
            />
          </Box>
          {formData.userType === 'Company' && (
            <Box mb={2}>
              <TextField
                id="companyName"
                label="Company Name"
                type="text"
                name="companyName"
                fullWidth
                variant="outlined"
                value={formData.companyName}
                onChange={(e) => handleChange(e)}
              />
            </Box>
          )}
          <Box mb={2}>
            <TextField
              id="street"
              label="Street Address"
              type="text"
              name="address.street"
              required
              fullWidth
              variant="outlined"
              value={formData.address.street}
              onChange={(e) => handleChange(e, true)}
              error={!!errors['address.street']}
              helperText={errors['address.street']}
            />
          </Box>
          <Box mb={2}>
            <TextField
              id="city"
              label="City"
              type="text"
              name="address.city"
              required
              fullWidth
              variant="outlined"
              value={formData.address.city}
              onChange={(e) => handleChange(e, true)}
              error={!!errors['address.city']}
              helperText={errors['address.city']}
            />
          </Box>
          <Box mb={2}>
            <TextField
              id="state"
              label="State"
              type="text"
              name="address.state"
              required
              fullWidth
              variant="outlined"
              value={formData.address.state}
              onChange={(e) => handleChange(e, true)}
              error={!!errors['address.state']}
              helperText={errors['address.state']}
            />
          </Box>
          <Box mb={2}>
            <TextField
              id="zip"
              label="ZIP Code"
              type="text"
              name="address.zip"
              required
              fullWidth
              variant="outlined"
              value={formData.address.zip}
              onChange={(e) => handleChange(e, true)}
              error={!!errors['address.zip']}
              helperText={errors['address.zip']}
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {loading ? 'Submitting...' : 'Complete Registration'}
          </Button>
        </form>
      )}
    </Container>
  );
}

export default Register;
