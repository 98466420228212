import axios from 'axios';
import { toast } from 'react-hot-toast';

//const API_URL = 'http://localhost:5000/api';
const API_URL = 'https://psec.prasanvi.com/api';
//
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Add a request interceptor to attach the token to every request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Add a response interceptor to handle 401 errors globally
api.interceptors.response.use(
  (response) => response, // Forward valid responses
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized errors
        toast.error('Login session expired. Please log in again.'); // Show toast notification

      // Redirect the user to the login page
        localStorage.removeItem('token');
        window.location.href = '/';

    }
    return Promise.reject(error); // Reject the error for further handling if needed
  }
);

export const auth = {
  login: async (email) => {
    const response = await api.post('/auth/verify_login_email', { 'email_id': email });
    return response.data;
  },
  verifyOTP: async (email, otp) => {
    const response = await api.post('/auth/verify_login_otp', { 'email_id': email, 'otp': otp });
    return response.data;
  },
  registerEmail: async (userData) => {
    const response = await api.post('/auth/verify_register_email', userData);
    return response.data;
  },
  registerOTP: async (userData) => {
    const response = await api.post('/auth/verify_register_otp', userData);
    return response.data;
  },
  register: async (userData) => {
    const response = await api.post('/auth/add_user', userData);
    return response.data;
  },
  forgotPassword: async (email) => {
    const response = await api.post('/auth/forgot-password', { email });
    return response.data;
  },
  updateProfile: async (userData) => {
    const response = await api.post('/auth/update_profile', userData);
    return response.data;
  },
  validateToken: async (token) => {
    const response = await api.post('/auth/validate_token', { token });
    return response.data;
  }
}

export const analysis = {
  submit: async ({ file, name }) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', file);
    const response = await api.post('/analysis/run_analysis', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  },
  rerun: async (id) => {
    const formData = {'uid': id}
    const response = await api.post(`/analysis/rerun_analysis`, formData);
    return response.data;
  },
  checkName: async (name) => {
    const formData = {
      params: { name },
    }
    const response = await api.get('/analysis/check_analysis_name', formData);
    return response.data;
  },
  getAll: async () => {
    const response = await api.get('/analysis/list');
    return response.data;
  },
  getDashData: async () => {
    const response = await api.get('/analysis/get_dashboard_data');
    return response.data;
  },
  getScoreData: async (id) => {
    const response = await api.get(`/analysis/get_score_card/${id}`);
    return response.data;
  },
  getById: async (id) => {
    const response = await api.get(`/analysis/get_analysis_data/${id}`);
    return response.data;
  },
  delete: async (id) => {
    const response = await api.delete(`/analysis/delete/${id}`);
    return response.data;
  },
  // subscribeToUpdates: (onMessage, onError) => {
  //   const token = localStorage.getItem('token');
  //   if (!token) {
  //     console.error('No token found in localStorage');
  //     return;
  //   }
  //   const url = `${API_URL}/static/events?token=${encodeURIComponent(token)}`;
  //   const eventSource = new EventSource(url);

  //   eventSource.onmessage = (event) => {
  //     const data = JSON.parse(event.data);
  //     const type = data.type
  //     if (type === 'analysis_complete'){
  //       toast.success(data.name + ' Analysis has been completed successfully, Please Check the Report')
  //     } else if(type === 'analysis_error'){
  //       toast.error('Error While Running Analysis for '+data.name+' , Please Try Again After Sometime')
  //     }
  //     if (onMessage) {
  //       onMessage(data);
  //     }
  //   };

  //   eventSource.onerror = (error) => {
  //     console.error("SSE connection error:", error);
  //     if (onError) {
  //       onError(error);
  //     }
  //     eventSource.close();
  //   };

  //   // Return a method to close the connection when needed
  //   return () => eventSource.close();
  // },
  downloadPDF: async (id) => {
    const response = await api.get(`/analysis/download_pdf/${id}`, {
      responseType: 'blob',
    });
    return response;
  }
};

export default api;
