import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid2,
  Typography,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TextField,
  CircularProgress,
  Modal,
  Button
} from '@mui/material';
import {
  TrendingUp as TrendingUpIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { styled, keyframes } from '@mui/system';
import { analysis } from '../services/api';
import { toast } from 'react-hot-toast';
import alphaLogo from '../assets/images/prasanvi-alpha.png';

function Dashboard() {
  const [summaryData, setSummaryData] = useState({});
  const [scanData, setScanData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingScanId, setLoadingScanId] = useState(null);
  const [showTips, setShowTips] = useState(false);
    
    const securityTips = [
      'Always verify app permissions before installing an APK.',
      'Avoid installing APK files from untrusted sources.',
      'Enable Google Play Protect to scan apps for malware.',
      'Regularly update your Android OS for the latest security patches.',
      'Use strong, unique passwords and enable two-factor authentication.',
      'Encrypt sensitive data stored on your Android device.',
      'Use a VPN to enhance privacy and security on public networks.',
      'Keep backups of important data to avoid loss in case of attacks.',
      'Enable screen lock with strong PINs or biometrics for added security.',
      'Disable developer options when not in use to prevent unauthorized access.',
    ];
    
    const [tip, setTip] = useState(securityTips[0]);

      const rotate = keyframes`
        from {
          transform: rotateX(0deg);
        }
        to {
          transform: rotateX(360deg);
        }
      `;
    
      // Styled component for rotating logo
      const RotatingLogo = styled('img')({
        width: '80px',
        height: '80px',
        animation: `${rotate} 2s linear infinite`,
      });

    const stats = [
    {
      title: 'Total Scans',
      value: summaryData?.total_scans ?? 0,
      icon: TrendingUpIcon,
      color: '#4f46e5',
    },
    {
      title: 'Critical Issues',
      value: summaryData?.critical_issues ?? 0,
      icon: WarningIcon,
      color: '#ef4444',
    },
    {
      title: 'Clean APKs',
      value: summaryData?.clean_apks ?? 0,
      icon: CheckCircleIcon,
      color: '#22c55e',
    },
    {
      title: 'Pending Analysis',
      value: summaryData?.pending_analysis ?? 0,
      icon: ScheduleIcon,
      color: '#f59e0b',
    },
  ];

    useEffect(() => {
      let interval;
    
      if (loadingScanId) {
        setShowTips(true); // Show popover when loading starts
        interval = setInterval(() => {
          setTip(securityTips[Math.floor(Math.random() * securityTips.length)]);
        }, 4000); // Change tip every 4 seconds
      } else {
        setShowTips(false); // Hide popover when loading ends
        clearInterval(interval);
      }
    
      return () => clearInterval(interval);
    }, [loadingScanId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await analysis.getDashData();
        setSummaryData(result.summary_data);
        setScanData(result.scan_data);
        setFilteredData(result.scan_data);
      } catch (error) {
        toast.error('Failed to fetch analysis results');
      }
    };
    fetchData();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    if (value === '') {
      setFilteredData(scanData);
    } else {
      const filtered = scanData.filter(
        (scan) =>
          scan.name.toLowerCase().includes(value) ||
          scan.app_name.toLowerCase().includes(value)
      );
      setFilteredData(filtered);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'primary.main';
      case 'Critical':
        return 'error.main';
      case 'Clean':
        return 'success.main';
      default:
        return 'warning.main';
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await analysis.delete(id);
      if (res.status === 'success') {
        toast.success('Scan deleted successfully');
        const scanToDelete = scanData.find((scan) => scan.id === id);
        if (scanToDelete) {
          if (scanToDelete.scan_status === 'Pending') {
            setSummaryData({
              ...summaryData,
              pending_analysis: summaryData.pending_analysis - 1,
            });
          } else if (scanToDelete.scan_status === 'Completed') {
            setSummaryData({
              ...summaryData,
              clean_apks: summaryData.clean_apks - 1,
            });
          }
        }
        setSummaryData({ ...summaryData, total_scans: summaryData.total_scans - 1 });
        const updatedData = scanData.filter((scan) => scan.id !== id);
        setScanData(updatedData);
        setFilteredData(updatedData);
      } else {
        toast.error('Failed to delete scan');
      }
    } catch (error) {
      toast.error('Failed to delete scan');
    }
  };
  const handleReRun = async (id, name) => {
    setLoadingScanId(id); // Set loading state
    try {
      const response = await analysis.rerun(id); // Example API call
      if (response.status === 'success') {
        // 'Re-run of ' + name + ' successfully Completed'
        toast.success('Re-run of ' + name + ' successfully Completed');
      } else {
        // message = 'Failed to initiate re-run of ' + name
        toast.error('Failed to initiate re-run of ' + name);
      }
    } catch (error) {
      toast.error('An error occurred while initiating re-run');
    } finally {
      setLoadingScanId(null); // Reset loading state
    }
  };

  const handleView = (id) => {
    window.location.href = `/scan/${id}`;
  };

  return (
    <>
    <Box>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Dashboard
      </Typography>

      <Grid2 container spacing={3} sx={{ mb: 4 }}>
        {stats.map((stat) => (
          <Grid2 item xs={12} sm={6} md={3} key={stat.title}>
            <Card>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    mr: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 48,
                    height: 48,
                    borderRadius: 1,
                    bgcolor: `${stat.color}15`,
                  }}
                >
                  <stat.icon sx={{ color: stat.color }} />
                </Box>
                <Box>
                  <Typography color="text.secondary" variant="body2">
                    {stat.title}
                  </Typography>
                  <Typography variant="h5" sx={{ mt: 0.5 }}>
                    {stat.value}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>

      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Typography variant="h6">Recent Scans</Typography>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search Scans"
              value={searchTerm}
              onChange={handleSearch}
              sx={{ width: 300 }}
            />
          </Box>

          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              height: 300,
              maxHeight: 300,
              overflowY: 'auto',
            }}
          >
            {filteredData.length === 0 ? (
              <Box sx={{ textAlign: 'center', py: 10 }}>No scans performed</Box>
            ) : (
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Analysis Name</TableCell>
                    <TableCell>App Name</TableCell>
                    <TableCell>Timestamp</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Security Score</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((scan) => (
                    <TableRow key={scan.id}>
                      <TableCell>{scan.name}</TableCell>
                      <TableCell>{scan.app_name}</TableCell>
                      <TableCell>{scan.created_at}</TableCell>
                      <TableCell>
                        <Typography
                          component="span"
                          sx={{
                            color: getStatusColor(scan.scan_status),
                            bgcolor: `${getStatusColor(scan.scan_status)}15`,
                            px: 1,
                            py: 0.5,
                            borderRadius: 1,
                            fontSize: '0.875rem',
                          }}
                        >
                          {scan.scan_status}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {scan.security_score ? (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <LinearProgress
                              variant="determinate"
                              value={scan.security_score}
                              sx={{ width: 100 }}
                            />
                            <Typography variant="body2">{scan.security_score}%</Typography>
                          </Box>
                        ) : (
                          '—'
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                          <Tooltip title="Delete">
                            <DeleteIcon
                              sx={{ cursor: 'pointer', color: 'error.main' }}
                              onClick={() => handleDelete(scan.id, scan.name)}
                            />
                          </Tooltip>
                          <Tooltip title="Re-run">
                              <Box>
                                {loadingScanId === scan.id ? (
                                  <CircularProgress size={24} />
                                ) : (
                                  <RefreshIcon
                                    sx={{ cursor: 'pointer', color: 'info.main' }}
                                    onClick={() => handleReRun(scan.id)}
                                  />
                                )}
                              </Box>
                            </Tooltip>
                          <Tooltip
                            title={
                              scan.scan_status === 'Completed'
                                ? 'View'
                                : 'View disabled for incomplete scans'
                            }
                          >
                            <VisibilityIcon
                              sx={{
                                cursor: scan.scan_status === 'Completed' ? 'pointer' : 'not-allowed',
                                color: scan.scan_status === 'Completed'
                                  ? 'primary.main'
                                  : 'text.disabled',
                              }}
                              onClick={
                                scan.scan_status === 'Completed'
                                  ? () => handleView(scan.id)
                                  : undefined
                              }
                            />
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
    <Modal
        open={showTips}
        onClose={() => setShowTips(false)}
        disableEscapeKeyDown={true}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(), // Prevent closing on backdrop click
        }}
        aria-labelledby="security-tip-title"
        aria-describedby="security-tip-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" id="security-tip-title" sx={{ mb: 2 }}>
            Security Tip
          </Typography>
          <RotatingLogo src={alphaLogo} alt="logo" />
          <Typography variant="body1" id="security-tip-description" sx={{ mb: 5 }}>
            {tip}
          </Typography>
          {/* <Button variant="contained" onClick={() => setShowTips(false)}>
            Got it!
          </Button> */}
        </Box>
      </Modal>
    </>
  );
}

export default Dashboard;


