import React from "react";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { analysis } from "../services/api";


const DownloadButton = ({ id ,text = "Download", color = "grey" }) => {
    const handleDownload = async () => {
        try {

            // Fetch the file from the server
            const response = await analysis.downloadPDF(id);
            console.log(response, 'response');
          if (response.status !== 200) {
            throw new Error("Failed to download file");
          }
    
          // Extract the filename from the Content-Disposition header
          const contentDisposition = response.headers.get("Content-Disposition");
          let filename = "downloaded_file";
          if (contentDisposition) {
            const match = contentDisposition.match(/filename="?(.+)"?/);
            if (match) filename = match[1];
          }
    
          // Convert response to a blob
          const blob = new Blob([response.data], { type: 'application/pdf' });
    
          // Create a temporary URL for the blob and trigger download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename; // Set the filename
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error downloading file:", error);
        }
      };
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: color === "blue" ? "#1976d2" : "#757575", 
        color: "white", 
        padding: "8px 16px", 
        borderRadius: "8px", 
        textTransform: "none", 
        '&:hover': {
          backgroundColor: color === "blue" ? "#1565c0" : "#616161",
        },
      }}
      startIcon={<DownloadIcon />} 
        onClick={handleDownload}
    >
      {text}
    </Button>
  );
};

export default DownloadButton;
