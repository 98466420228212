import React from 'react';
import { useState, useEffect } from 'react';
import { Box, Grid2, Card, CardContent, Typography, CircularProgress, Chip, Divider, List, ListItem, ListItemText } from '@mui/material';
import Chart from 'react-apexcharts';
import { analysis } from '../services/api';
import { useParams } from 'react-router-dom';


const ScoreCard = () => {

    const { scanId } = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
        try {
            // const response = await axios.get('/api/mobsf-data'); // Replace with your API endpoint
            const response = await analysis.getScoreData(scanId);
            setData(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchData();
    }, []);

    if (!data) {
        return <Typography>Loading...</Typography>;
    }

  const severityChartOptions = {
    chart: { type: 'pie' },
    labels: ['High', 'Medium', 'Info', 'Secure'],
    colors: [ "#dc3545",  "#ffc107", "#17a2b8",  "#28a745"],
  };

  const severityChartData = [
    data.severity.hp,
    data.severity.wp,
    data.severity.ip,
    data.severity.sp,
  ];

  const riskRatingChartOptions = {
    chart: { type: 'radialBar' },
    plotOptions: {
      radialBar: {
        hollow: { size: '60%' },
        dataLabels: {
          name: { fontSize: '16px' },
          value: { fontSize: '14px' },
        },
      },
    },
    labels: ['Risk Rating'],
  };

  const riskRatingChartData = [data.security_score];

  return (
    <Box sx={{ padding: 2 }}>
      {/* Top Score and Summary */}
      <Grid2 container spacing={2}>
        <Grid2 item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Security Score</Typography>
              <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <CircularProgress variant="determinate" value={data.security_score} size={80} thickness={5} />
                <Typography
                  variant="h5"
                  color="textPrimary"
                  sx={{ position: 'absolute' }}
                >
                  {data.security_score}/100
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Risk Rating</Typography>
              <Chart
                options={riskRatingChartOptions}
                series={riskRatingChartData}
                type="radialBar"
                height={150}
              />
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Severity Distribution</Typography>
              <Chart
                options={severityChartOptions}
                series={severityChartData}
                type="pie"
                height={150}
              />
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Privacy Risk</Typography>
              <Box mt={2}>
                <Chip label={`${data.trackers} User/Device Trackers`} color="success" size="large" sx={{ fontSize: '1rem' }} />
              </Box>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>

      {/* Findings Section */}
      {/* <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Findings
        </Typography>
        <Divider />
        <List>
          {data.main_findings.map((finding, index) => (
            <ListItem key={index}>
              <Chip label={finding.severity} color={finding.severity === 'High' ? 'error' : 'warning'} size="small" sx={{ marginRight: 1 }} />
              <ListItemText
                primary={finding.title}
                secondary={finding.description}
              />
            </ListItem>
          ))}
        </List>
      </Box> */}
      {/* <Box mt={4}>
  <Typography variant="h6" gutterBottom>
    Findings
  </Typography>
  <Divider />
  <List>
    {Object.keys(data).map((severityKey) => (
      Array.isArray(data[severityKey]) &&
      data[severityKey].map((finding, index) => (
        <ListItem key={`${severityKey}-${index}`}>
          <Chip
            label={severityKey.charAt(0).toUpperCase() + severityKey.slice(1)}
            color={
              severityKey === 'high'
                ? 'error'
                : severityKey === 'warning'
                ? 'warning'
                : severityKey === 'secure'
                ? 'success'
                : 'default'
            }
            size="small"
            sx={{ marginRight: 1 }}
          />
          <ListItemText
            primary={finding.title}
            secondary={finding.description}
          />
        </ListItem>
      ))
    ))}
  </List>
</Box> */}
<Box mt={4}>
  <Typography variant="h6" gutterBottom>
    Findings
  </Typography>
  <Divider />
  <List>
    {Object.keys(data.main_findings).map((severityKey) => (
      Array.isArray(data.main_findings[severityKey]) &&
      data.main_findings[severityKey].map((finding, index) => (
        <ListItem key={`${severityKey}-${index}`}>
          <Chip
            label={severityKey.charAt(0).toUpperCase() + severityKey.slice(1)}
            color={
              severityKey === 'high'
                ? 'error'
                : severityKey === 'warning'
                ? 'warning'
                : severityKey === 'secure'
                ? 'success'
                : 'default'
            }
            size="small"
            sx={{ marginRight: 1 }}
          />
          <ListItemText
            primary={finding.title}
            secondary={finding.description}
          />
        </ListItem>
      ))
    ))}
  </List>
</Box>


    </Box>
  );
};

export default ScoreCard;


// import React from 'react';
// import { Box, Grid2, Card, CardContent, Typography, CircularProgress, Chip, Divider, List, ListItem, ListItemText } from '@mui/material';

// const ScoreCard = () => {
//   return (
//     <Box sx={{ padding: 2 }}>
//       {/* Top Score and Summary */}
//       <Grid2 container spacing={2}>
//         <Grid2 item xs={12} sm={6} md={3}>
//           <Card>
//             <CardContent>
//               <Typography variant="h6">Security Score</Typography>
//               <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
//                 <CircularProgress variant="determinate" value={44} size={80} thickness={5} />
//                 <Typography
//                   variant="h5"
//                   color="textPrimary"
//                   sx={{ position: 'absolute' }}
//                 >
//                   44/100
//                 </Typography>
//               </Box>
//             </CardContent>
//           </Card>
//         </Grid2>
//         <Grid2 item xs={12} sm={6} md={3}>
//           <Card>
//             <CardContent>
//               <Typography variant="h6">Risk Rating</Typography>
//               <Box mt={2}>
//                 <Chip label="Medium Risk" color="warning" size="large" sx={{ fontSize: '1rem' }} />
//               </Box>
//             </CardContent>
//           </Card>
//         </Grid2>
//         <Grid2 item xs={12} sm={6} md={3}>
//           <Card>
//             <CardContent>
//               <Typography variant="h6">Severity Distribution (%)</Typography>
//               <Box mt={2}>
//                 {/* Placeholder for chart */}
//                 <Typography variant="body2">High: 40%, Medium: 35%, Secure: 25%</Typography>
//               </Box>
//             </CardContent>
//           </Card>
//         </Grid2>
//         <Grid2 item xs={12} sm={6} md={3}>
//           <Card>
//             <CardContent>
//               <Typography variant="h6">Privacy Risk</Typography>
//               <Box mt={2}>
//                 <Chip label="0 User/Device Trackers" color="success" size="large" sx={{ fontSize: '1rem' }} />
//               </Box>
//             </CardContent>
//           </Card>
//         </Grid2>
//       </Grid2>

//       {/* Findings Section */}
//       <Box mt={4}>
//         <Typography variant="h6" gutterBottom>
//           Findings
//         </Typography>
//         <Divider />
//         <List>
//           <ListItem>
//             <Chip label="High" color="error" size="small" sx={{ marginRight: 1 }} />
//             <ListItemText
//               primary="Application vulnerable to Janus Vulnerability"
//               secondary="Application is signed with v1 signature scheme, making it vulnerable to Janus vulnerability on Android 5.0-8.0."
//             />
//           </ListItem>
//           <ListItem>
//             <Chip label="High" color="error" size="small" sx={{ marginRight: 1 }} />
//             <ListItemText
//               primary="Application signed with debug certificate"
//             />
//           </ListItem>
//           <ListItem>
//             <Chip label="High" color="error" size="small" sx={{ marginRight: 1 }} />
//             <ListItemText
//               primary="Certificate algorithm vulnerable to hash collision"
//             />
//           </ListItem>
//           <ListItem>
//             <Chip label="High" color="error" size="small" sx={{ marginRight: 1 }} />
//             <ListItemText
//               primary="App can be installed on a vulnerable unpatched Android version"
//             />
//           </ListItem>
//           <ListItem>
//             <Chip label="Medium" color="warning" size="small" sx={{ marginRight: 1 }} />
//             <ListItemText
//               primary="Application Data can be Backed up"
//             />
//           </ListItem>
//           <ListItem>
//             <Chip label="Medium" color="warning" size="small" sx={{ marginRight: 1 }} />
//             <ListItemText
//               primary="Broadcast Receiver (.MainBroadcastReceiver) is not Protected"
//             />
//           </ListItem>
//           <ListItem>
//             <Chip label="Medium" color="warning" size="small" sx={{ marginRight: 1 }} />
//             <ListItemText
//               primary="Service (.MainService) is not Protected"
//             />
//           </ListItem>
//           <ListItem>
//             <Chip label="Medium" color="warning" size="small" sx={{ marginRight: 1 }} />
//             <ListItemText
//               primary="The App uses an insecure Random Number Generator"
//             />
//           </ListItem>
//         </List>
//       </Box>
//     </Box>
//   );
// };

// export default ScoreCard;
