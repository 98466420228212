import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Login from './pages/login';
// import Register from './pages/register';
import Register from './pages/newRegister';
import Dashboard from './pages/dashboard';
import Analysis from './pages/analysis';
import Profile from './pages/profile';
import Layout from './components/layout';
import StaticAnalysisDashboard from './pages/staticReport';
import PrivateRoute from './components/privateRoute';
import ScoreCard from './pages/scoreCard';
import ContactUs from './pages/contactUs';
// import { NotificationProvider } from './components/notificationContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4f46e5',
    },
    background: {
      default: '#f9fafb',
    },
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <WebSocketProvider> */}
      <BrowserRouter>
        <Toaster position="top-right" />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                {/* <NotificationProvider> */}
                <Layout />
                {/* </NotificationProvider> */}
              </PrivateRoute>
            }
          >
            <Route index element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="analysis" element={<PrivateRoute><Analysis /></PrivateRoute>} />
            <Route path="profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          </Route>
          {/* <Route path="/scan/:scanId" element={<PrivateRoute><NotificationProvider><StaticAnalysisDashboard /></NotificationProvider></PrivateRoute>} /> */}
          <Route path="/scan/:scanId" element={<PrivateRoute><StaticAnalysisDashboard /></PrivateRoute>} />
          <Route path="/scorecard/:scanId" element={<PrivateRoute><ScoreCard /></PrivateRoute>} />
        </Routes>
      </BrowserRouter>
      {/* </WebSocketProvider> */}
    </ThemeProvider>
  );
}

export default App;

