import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  CircularProgress,
  Modal
} from '@mui/material';
import {
  Upload as UploadIcon,
  Security as SecurityIcon,
} from '@mui/icons-material';
import { styled, keyframes } from '@mui/system';
import toast from 'react-hot-toast';
import { analysis } from '../services/api';
import { useNavigate } from 'react-router-dom';
import alphaLogo from '../assets/images/prasanvi-alpha.png';


function Analysis() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [analysisName, setAnalysisName] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [showTips, setShowTips] = useState(false);
  
  const securityTips = [
    'Always verify app permissions before installing an APK.',
    'Avoid installing APK files from untrusted sources.',
    'Enable Google Play Protect to scan apps for malware.',
    'Regularly update your Android OS for the latest security patches.',
    'Use strong, unique passwords and enable two-factor authentication.',
    'Encrypt sensitive data stored on your Android device.',
    'Use a VPN to enhance privacy and security on public networks.',
    'Keep backups of important data to avoid loss in case of attacks.',
    'Enable screen lock with strong PINs or biometrics for added security.',
    'Disable developer options when not in use to prevent unauthorized access.',
  ];
  
  const rotate = keyframes`
    from {
      transform: rotateX(0deg);
    }
    to {
      transform: rotateX(360deg);
    }
  `;

  // Styled component for rotating logo
  const RotatingLogo = styled('img')({
    width: '80px',
    height: '80px',
    animation: `${rotate} 2s linear infinite`,
  });

  const [tip, setTip] = useState(securityTips[0]);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile?.type === 'application/vnd.android.package-archive' || selectedFile?.name.endsWith('.apk') || selectedFile?.name.endsWith('.APK') || selectedFile?.name.endsWith('.xapk') || selectedFile?.name.endsWith('.XAPK')) {
      setFile(selectedFile);
      setFileError(false);
    } else {
      setFile(null);
      setFileError(true);
      toast.error('Please select a valid APK file');
    }
  };

  const handleNameChange = (event) => {
    const name = event.target.value;
    setAnalysisName(name);
    setNameError(!name.trim());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file || !analysisName.trim()) {
      toast.error('Please provide both a valid APK file and an analysis name.');
      return;
    }

    setLoading(true);

    try{
      const res = await analysis.checkName(analysisName);
    } catch(error) {
      if (error.response.status === 409) {
        setNameError(true);
        toast.error('Analysis Name already exists. Please choose a different name.');
      } else {
        toast.error('Please Try Again Later')
      }
      setLoading(false)
      return
    }
    try {
      const result = await analysis.submit({ file, name : analysisName });
      setResults(result);
      toast.success('Analysis Completed Successfully');
      navigate('/dashboard');
    } catch (error) {
      toast.error('Failed to analyze APK');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let interval;
  
    if (loading) {
      setShowTips(true); // Show popover when loading starts
      interval = setInterval(() => {
        setTip(securityTips[Math.floor(Math.random() * securityTips.length)]);
      }, 4000); // Change tip every 4 seconds
    } else {
      setShowTips(false); // Hide popover when loading ends
      clearInterval(interval);
    }
  
    return () => clearInterval(interval);
  }, [loading]);
  

  

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 4 }}>
        APK Analysis
      </Typography>

        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Card sx={{width: '60%'}}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Upload APK
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <TextField
                  label="Analysis Name"
                  variant="outlined"
                  fullWidth
                  value={analysisName}
                  onChange={handleNameChange}
                  error={nameError}
                  helperText={nameError ? "Analysis name is required" : ""}
                />

                {/* APK File Upload Box */}
                <Box
                  sx={{
                    border: '2px dashed',
                    borderColor: 'primary.main',
                    borderRadius: 1,
                    p: 3,
                    textAlign: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: 'action.hover',
                    },
                  }}
                  onClick={() => document.getElementById('apk-file').click()}
                >
                  <input
                    id="apk-file"
                    type="file"
                    accept=".apk"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <UploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 1 }} />
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Drop your APK file here or click to browse
                  </Typography>
                  {file && (
                    <Typography variant="body2" color="primary">
                      Selected: {file.name}
                    </Typography>
                  )}
                  {fileError && (
                    <Typography variant="body2" color="error">
                      Only APK / XAPK files are allowed.
                    </Typography>
                  )}
                </Box>

                {/* Submit Button */}
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!file || loading || nameError}
                  startIcon={loading ? <CircularProgress size={20} /> : <SecurityIcon />}
                >
                  {loading ? 'Analyzing...' : 'Start Analysis'}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Modal
        open={showTips}
        onClose={() => setShowTips(false)}
        disableEscapeKeyDown={true}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(), // Prevent closing on backdrop click
        }}
        aria-labelledby="security-tip-title"
        aria-describedby="security-tip-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" id="security-tip-title" sx={{ mb: 2 }}>
            Security Tip
          </Typography>
          <RotatingLogo src={alphaLogo} alt="logo" />
          <Typography variant="body1" id="security-tip-description" sx={{ mb: 5 }}>
            {tip}
          </Typography>
          {/* <Button variant="contained" onClick={() => setShowTips(false)}>
            Got it!
          </Button> */}
        </Box>
      </Modal>
    </Box>
    
  );
}

export default Analysis;

