import React from "react";
import { Box, AppBar, Toolbar, Typography, Container, Link } from "@mui/material";
import prasanviLogo from "../assets/images/prasanvi-logo-text.png";

const ContactUs = () => {
  return (
    <Box sx={{ height: "100vh", backgroundColor: "#f5f5f5" }}>
      {/* Header */}
      <AppBar position="static" sx={{ backgroundColor: "#fff" }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            {/* Logo or Company Name */}
            <Link href="/" underline="none">
              <img src={prasanviLogo} alt="Prasanvi Logo" height="50" />
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "calc(100vh - 64px)", // Adjust height minus the header height
        }}
      >
        {/* Page Title */}
        <Typography variant="h1" color="primary" sx={{color: "#022d62", fontWeight:700}} gutterBottom>
          Contact Us
        </Typography>

        {/* Description Text */}
        <Typography variant="body1" color="textSecondary" sx={{ fontSize: "18px", maxWidth: 600, lineHeight:1.5, mb: 3, color: "#121212" }}>
          Drop us a line! We’ll set up a time to chat over the phone or in-person to know more about your business needs.
          All primary conferences are free of charge.
        </Typography>

        {/* Email Address */}
        <Typography variant="h6" color="primary">
          Email us at:{" "}
          <Link href="mailto:contactus@prasanvi.com" underline="hover" color="inherit">
            contactus@prasanvi.com
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default ContactUs;
