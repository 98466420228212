import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {CircularProgress, Link} from '@mui/material';
import DOMPurify from "dompurify";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Grid2,
  Badge,
  Button,
  Divider,
  LinearProgress,
  Chip,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  Settings as SettingsIcon,
  List as ListIcon,
  FontDownload as FontDownloadIcon,
  FileCopy as FileCopyIcon,
  CheckCircle as CheckCircleIcon,
  Google as GoogleIcon,
  Build as BuildIcon,
  Language as LanguageIcon,
  Storage as StorageIcon,
  Map as MapIcon,
  Email as EmailIcon,
  Person4 as UserSecretIcon,
  Key as KeyIcon,
  Hearing as AssistiveListeningSystemsIcon,
  Score as ScoreIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { analysis } from '../services/api';
import { toast } from 'react-hot-toast';
import ListComponent from '../components/listComponent';
import Sidebar from '../components/sideBar';
import MapWithLocations from '../components/worldMap';
import DownloadButton from '../components/downloadButton';
import Chart from 'react-apexcharts';


const StaticAnalysisDashboard = () => {

  const { scanId } = useParams();
  const [appScores, setAppScores] = useState({});
  const [fileInfo, setFileInfo] = useState({});
  const [appInfo, setAppInfo] = useState({});
  const [playStoreDetails, setPlayStoreDetails] = useState({});
  const [signerInfo, setSignerInfo] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [androidAPI, setAndroidAPI] = useState([]);
  const [browsableActivities, setBrowsableActivities] = useState([]);
  const [networkSecurity, setNetworkSecurity] = useState({});
  const [certificateAnalysis, setCertificateAnalysis] = useState({'data': [], 'summary': {}});
  const [manifestAnalysis, setManifestAnalysis] = useState({'data': [], 'summary': {}});
  const [codeAnalysis, setCodeAnalysis] = useState({'data': [], 'summary': {}});
  const [sharedLibInfo, setSharedLibInfo] = useState([]);
  const [permissionAnalysis, setPermissionAnalysis] = useState({'top_malware_permissions': [], 'total_malware_permissions': 0, 'other_abused_permissions': [], 'total_other_permissions': 0});
  const [niapAnalysis, setNiapAnalysis] = useState({});
  const [fileAnalysis, setFileAnalysis] = useState([]);
  const [apkIdAnalysis, setApkIdAnalysis] = useState({});
  const [domainAnalysis, setDomainAnalysis] = useState({});
  const [trackersAnalysis, setTrackersAnalysis] = useState([]);
  const [secretsAnalysis, setSecretsAnalysis] = useState([]);
  const [stringsAnalysis, setStringsAnalysis] = useState({'strings': { 'strings_apk_res': [], 'strings_code': [], 'strings_so': [] }, 'summary': {}});
  const [emailsAnalysis, setEmailsAnalysis] = useState([]);
  const [urlsAnalysis, setUrlsAnalysis] = useState([]);
  const [activitiesAnalysis, setActivitiesAnalysis] = useState([]);
  const [servicesAnalysis, setServicesAnalysis] = useState([]);
  const [receiversAnalysis, setReceiversAnalysis] = useState([]);
  const [providersAnalysis, setProvidersAnalysis] = useState([]);
  const [librariesAnalysis, setLibrariesAnalysis] = useState([]);
  const [firebaseAnalysis, setFirebaseAnalysis] = useState([]);
  const [filesAnalysis, setFilesAnalysis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [iconData, setIconData] = useState(null);
  const [scoreCardData, setScoreCardData] = useState({});
  const [showNavigation, setShowNavigation] = useState(true);
  const mainContainerRef = useRef();
  const [selectedSection, setSelectedSection] = useState('information');
  const [severityChartData, setSeverityChartData] = useState([]);
  const [riskRatingChartData, setRiskRatingChartData] = useState([]);
  const [securityScore, setSecurityScore] = useState(0);
  const [severityData, setSeverityData] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [findings, setFindings] = useState([]);

  const parseSecretsData = (data) => {
    return data.map((item) => {
      // Remove the quotes and split by the ":"
      const [key, value] = item.replace(/"/g, '').split(' : ');
      return { key, value };
    });
  };

  useEffect(() => {
    // Fetch data here
    const fetchData = async () => {
      try {
        const result = await analysis.getById(scanId);
        console.log(result, 'result')
        setAppScores(result.app_scores);
        setFileInfo(result.file_information);
        setAppInfo(result.app_info);
        setPlayStoreDetails(result.play_store_info);
        setSignerInfo(result.signer_info);
        setPermissions(result.app_perm);
        setAndroidAPI(result.android_api);
        setBrowsableActivities(result.browsable_activities);
        setNetworkSecurity(result.network_security);
        setCertificateAnalysis(result.certificate_info);
        setPermissionAnalysis(result.permissions_info);
        console.log(result.permissions_info, 'permissions_info')
        setManifestAnalysis(result.manifest_info);
        setCodeAnalysis(result.code_info);
        setSharedLibInfo(result.shared_lib_info);
        setNiapAnalysis(result.niap_info);
        setFileAnalysis(result.file_info);
        setApkIdAnalysis(result.apkid_info);
        setDomainAnalysis(result.servers_info);
        console.log(result.servers_info, 'servers_info')
        setTrackersAnalysis(result.trackers_info);
        const secretsData = parseSecretsData(result.secrets_info);
        setSecretsAnalysis(secretsData);
        setStringsAnalysis(result.strings_info);
        setActivitiesAnalysis(result.activities_info);
        setServicesAnalysis(result.services_info);
        setReceiversAnalysis(result.receivers_info);
        setLibrariesAnalysis(result.libraries_info);
        setProvidersAnalysis(result.providers_info);
        setFilesAnalysis(result.files_info);
        setEmailsAnalysis(result.emails_info);
        setUrlsAnalysis(result.urls_info);
        setIconData(result.app_scores.icon);
        setFirebaseAnalysis(result.firebase_info);
        setScoreCardData(result.scorecard_data);
        const severityData = result.scorecard_data.severity;
        setSeverityData(severityData);
        setSeverityChartData([ severityData.hp, severityData.wp, severityData.ip, severityData.sp ]);
        setRiskRatingChartData([ result.scorecard_data.security_score ]);
        setSecurityScore(result.scorecard_data.security_score);
        setFindings(result.scorecard_data.main_findings);
        console.log(result.scorecard_data.main_findings, 'main_findings')

      } catch (error) {
        toast.error('Failed to fetch analysis results');
        // navigate('/dashboard');
      }
    }
    
    setLoading(true);
    fetchData();
    setLoading(false);
  }, []);

  const severityChartOptions = {
    chart: { type: 'pie' },
    labels: ['High', 'Medium', 'Info', 'Secure'],
    colors: [ "#dc3545",  "#ffc107", "#17a2b8",  "#28a745"],
  };
// Data is already grouped by severity
const groupedFindings = findings;

// Count the number of findings for each severity
const severityCount = {
  high: groupedFindings.high ? groupedFindings.high.length : 0,
  medium: groupedFindings.medium ? groupedFindings.medium.length : 0,
  info: groupedFindings.info ? groupedFindings.info.length : 0,
  secure: groupedFindings.secure ? groupedFindings.secure.length : 0,
  hotspot: groupedFindings.hotspot ? groupedFindings.hotspot.length : 0,
  warning: groupedFindings.warning ? groupedFindings.warning.length : 0, // Include warnings if applicable
};
const severityColors = {
  high: "#ff4d4f", // Red for high severity
  medium: "#faad14", // Orange for medium severity
  info: "#1890ff", // Blue for info severity
  secure: "#52c41a", // Green for secure severity
  hotspot: "#d32f2f", // Dark red for hotspot
  warning: "#ffc107", // Yellow for warning
};
  // const groupedFindings = findings.reduce((acc, finding) => {
  //   const severity = finding.severity || 'info'; // Default to 'info' if no severity is provided
  //   if (!acc[severity]) {
  //     acc[severity] = [];
  //   }
  //   acc[severity].push(finding);
  //   return acc;
  // }, {});

  // // Count the number of findings for each severity
  // const severityCount = {
  //   high: groupedFindings.high ? groupedFindings.high.length : 0,
  //   medium: groupedFindings.medium ? groupedFindings.medium.length : 0,
  //   info: groupedFindings.info ? groupedFindings.info.length : 0,
  //   secure: groupedFindings.secure ? groupedFindings.secure.length : 0,
  //   hotspot: groupedFindings.hotspot ? groupedFindings.hotspot.length : 0,
  // };

  const riskRatingChartOptions = {
    chart: { type: 'radialBar' },
    plotOptions: {
      radialBar: {
        hollow: { size: '60%' },
        dataLabels: {
          name: { fontSize: '16px' },
          value: { fontSize: '14px' },
        },
      },
    },
    labels: ['Risk Rating'],
  };

  const securityScoreOptions = {
    chart: {
      // height: 280,
      type: "radialBar",
    },
    colors: ["#20E647"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
          background: "#293450",
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15,
          },
        },
        dataLabels: {
          name: {
            offsetY: -10,
            color: "#fff",
            fontSize: "13px",
          },
          value: {
            color: "#fff",
            fontSize: "30px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        gradientToColors: ["#87D4F9"],
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Security Score"], // Label for the chart
  };

    // Calculate risk factor and label based on security score
    const getRiskFactor = (score) => {
      if (score < 30) return 3.9;
      if (score < 40) return 2.5;
      if (score < 60) return 1.5;
      return 0.5;
    };
  
    const getRiskLabel = (score) => {
      if (score < 30) return "Critical Risk";
      if (score < 40) return "High Risk";
      if (score < 60) return "Medium Risk";
      return "Low Risk";
    };
  
    const riskFactor = getRiskFactor(securityScore);
    const riskLabel = getRiskLabel(securityScore);
  
    const riskOptions = {
      chart: {
        // height: 280,
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true
        }
      },
      series: [riskFactor * 25], // Normalize risk factor to percentage (0.5 = 12.5%, 3.9 = 97.5%)
      colors: ["#20E647", "#FFD700", "#FFA500", "#DC3545"].slice(0, Math.ceil(riskFactor)),
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#444',
              opacity: 1,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        },
      },
      grid: {
        padding: {
          top: -10
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        },
      },
      labels: ["Risk Level"],
    };

  const [appType, setAppType] = React.useState('so');
  const [md5, setMd5] = React.useState('d41d8cd98f00b204e9800998ecf8427e');
    const [expandedRows, setExpandedRows] = React.useState({});
    
    const toggleRow = (index) => {
      setExpandedRows((prev) => ({ ...prev, [index]: !prev[index] }));
    };


    const renderSeverityBadge = (severity) => {
      const colorMap = {
        high: '#f44336', // Red for high severity
        warning: '#ffa726', // Orange for warning
        info: '#29b6f6', // Blue for info
        secure: '#66bb6a', // Green for secure
        dangerous: '#f44336', // Red for dangerous
        normal: '#29b6f6', // Blue for normal
        signatureOrSystem: '#ffa726', // Orange for signatureOrSystem
        
      };
      
      
      

      return (
        <Box
          sx={{
            display: 'inline-block',
            backgroundColor: colorMap[severity],
            color: '#fff',
            borderRadius: '5px',
            padding: '2px 8px',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            mr: 1,
          }}
        >
          {severity}
        </Box>
      );
    };
    
    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };

    // Group findings by severity
   
 
  return (
    <>
    (loading ? <CircularProgress /> : 

    <Sidebar setSelectedSection={setSelectedSection} />
    <Box ml={30} mt={0} >
      <Box width="95%" sx={{display:'flex', justifyContent: 'flex-end'}}>
      <DownloadButton id={scanId} />
      </Box>
    <Box p={3} ref={mainContainerRef}>
      { selectedSection === 'information' && (
        <Box>
          
      <Grid2 container spacing={2}>
        {/* App Scores */}
        <Grid2 item="true" xs={12} sm={4}>
          <Card sx={{width: 230, height:270}} id="information">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                <CheckCircleIcon fontSize="small" /> App Scores
              </Typography>
              <Divider />
              <Box mt={2}>
                <Box display="flex" justifyContent="center" sx={{width:'80px', height:'80px'}} mb={1}>
                  <img width={'100%'} height={'100%'} style={{objectFit: 'contain', objectPosition:'center'}} src={`data:image/png;base64,${iconData}`} alt="icon" />
                </Box>
                { appScores && appScores.cvss_score && (
                <Typography>
                  <Badge color="info">Average CVSS</Badge>: <strong>{appScores.cvss_score}</strong>
                </Typography>
                )}
                <Typography>
                  <Badge
                    color={appScores.security_score < 40 ? 'error' : appScores.security_score === 100 ? 'success' : 'warning'}
                  >
                    Security Score
                  </Badge>
                  : <strong>{appScores.security_score}/100</strong>
                </Typography>
                <Typography>
                  <Badge color="warning">Trackers Detection</Badge>: <strong>{appScores.detected_trackers}/{appScores.total_trackers}</strong>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid2>

        {/* File Information */}
        <Grid2 item="true" xs={12} sm={4}>
          <Card sx={{width: 400, height:270}} >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                <BuildIcon fontSize="small" /> File Information
              </Typography>
              <Divider />
              <Box mt={2}>
                <Typography sx={{fontSize:14}}>
                  <Badge color="primary" sx={{fontSize:14}}>File Name</Badge>: {fileInfo.file_name}
                </Typography>
                <Typography sx={{fontSize:14}}>
                  <Badge color="primary"  sx={{fontSize:14}}>Size</Badge>: {fileInfo.file_size}
                </Typography>
                <Typography sx={{fontSize:14}}>
                  <Badge color="primary"  sx={{fontSize:14}}>MD5</Badge>: {fileInfo.md5}
                </Typography>
                <Typography sx={{fontSize:14}}>
                  <Badge color="primary"  sx={{fontSize:14}}>SHA1</Badge>: {fileInfo.sha1}
                </Typography>
                <Typography sx={{fontSize:14}}>
                  <Badge color="primary" sx={{fontSize:14}}>SHA256</Badge>: {fileInfo.sha256}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid2>

        {/* App Information */}
        <Grid2 item="true" xs={12} sm={4}>
          <Card sx={{width: 320, height:270}}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                <ListIcon fontSize="small" /> App Information
              </Typography>
              <Divider />
              <Box mt={2}>
                <Typography sx={{fontSize:14}}>
                  <Badge color="primary">App Name</Badge>: {appInfo.app_name}
                </Typography>
                <Typography sx={{fontSize:14}}>
                  <Badge color="primary">Package Name</Badge>: {appInfo.package_name}
                </Typography>
                <Typography sx={{fontSize:14}}>
                  <Badge color="primary">Main Activity</Badge>: {appInfo.main_activity}
                </Typography>
                <Typography sx={{fontSize:14}}>
                  <Badge color="primary">Target SDK</Badge>: {appInfo.targetsdk}
                </Typography>
                <Typography sx={{fontSize:14}}>
                  <Badge color="primary">Min SDK</Badge>: {appInfo.minsdk}
                </Typography>
                <Typography sx={{fontSize:14}}>
                  <Badge color="primary">Max SDK</Badge>: {appInfo.maxsdk}
                </Typography>
                <Typography sx={{fontSize:14}}>
                  <Badge color="primary">Version Name</Badge>: {appInfo.androidversionname}
                </Typography>
                <Typography sx={{fontSize:14}}>
                  <Badge color="primary">Version Code</Badge>: {appInfo.androidversioncode}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid2>

      </Grid2>
        {/* Play Store Information */}
        <Box mt={2} width={'100%'}>
          <Card sx={{width:'100%'}}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                <GoogleIcon fontSize="small" /> Play Store Information
              </Typography>
              <Divider />
              <Box mt={2}>
                <Typography>
                  <Badge color="info">Title</Badge>: {playStoreDetails.title}
                </Typography>
                <Typography>
                  <Badge color="info">Score</Badge>: {playStoreDetails.score}
                </Typography>
                <Typography>
                  <Badge color="info">Installs</Badge>: {playStoreDetails.installs}
                </Typography>
                <Typography>
                  <Badge color="info">Price</Badge>: {playStoreDetails.price}
                </Typography>
                <Typography>
                  <Badge color="info">Android Version Support</Badge>: {playStoreDetails.androidVersionText}
                </Typography>
                <Typography>
                  <Badge color="info">Category</Badge>: {playStoreDetails.genre}
                </Typography>
                <Typography>
                  <Badge color="info">Developer</Badge>: {playStoreDetails.developer}
                </Typography>
                <Typography>
                  <Badge color="info">Developer ID</Badge>: {playStoreDetails.developerId}
                </Typography>
                <Typography>
                  <Badge color="info">Developer Address</Badge>: {playStoreDetails.developerAddress}
                </Typography>
                <Typography>
                  <Badge color="info">Developer Website</Badge>: {playStoreDetails.developerWebsite}
                </Typography>
                <Typography>
                  <Badge color="info">Developer Email</Badge>: {playStoreDetails.developerEmail}
                </Typography>
                <Typography>
                  <Badge color="info">Release Date</Badge>: {playStoreDetails.released}
                </Typography>
                <Typography>
                  <Badge color="info">Privacy Policy</Badge>:
                  <Link href={playStoreDetails.privacyPolicy} target="_blank" rel="noopener noreferrer">Privacy Link</Link>
                </Typography>
                <Typography>
                  <Badge color="info">Description</Badge>: 
                </Typography>
                <pre>
                  {playStoreDetails.description}
                </pre>
              </Box>
            </CardContent>
          </Card>
        </Box>
        </Box>
    )}
    { selectedSection === 'certificate' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="certificate">
            <CardContent>
              <Typography variant="h6"><i className="fa fa-certificate" /> Signer Certificate</Typography>
                <pre>{signerInfo}</pre>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'permissions_analysis' && (
        <Box width={'100%'} mt={2}>
          {/* Application Permissions */}
          <Card sx={{width:'100%'}} id="permissions_analysis">
            <CardContent>
              <Typography variant="h6"><i className="fa fa-list" /> Application Permissions</Typography>
              <TableContainer component={Paper}>
                <Table aria-label="permissions table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Permission</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Info</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Code Mappings</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissions.map((perm, index) => (
                      <TableRow key={index}>
                        <TableCell>{perm.permission}</TableCell>
                        <TableCell>
                          {renderSeverityBadge(perm.status)}
                        </TableCell>
                        <TableCell>{perm.info}</TableCell>
                        <TableCell>{perm.description}</TableCell>
                        <TableCell>
                          {perm.codeMappings && perm.codeMappings.length > 0 ? (
                            <>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => toggleRow(index)}
                              >
                                {expandedRows[index] ? 'Hide Files' : 'Show Files'}
                              </Button>
                              <Collapse in={expandedRows[index]} timeout="auto" unmountOnExit>
                                {perm.codeMappings.map((mapping, idx) => (
                                  <Typography variant="body2" key={idx}>
                                    <a href={`#`} target="_blank" rel="noopener noreferrer">
                                      {mapping.filePath} - Lines: {mapping.lines}
                                    </a>
                                  </Typography>
                                ))}
                              </Collapse>
                            </>
                          ) : (
                            <Typography>No Mappings</Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>  
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'android_api' && (
        <Box width={'100%'} mt={2}>
          {/* Android API Analysis */}
          <Card sx={{width:'100%'}} id="android_api">
            <CardContent>
              <Typography variant="h6"><i className="fab fa-android" /> Android API</Typography>
              <TableContainer component={Paper}>
                <Table aria-label="android api table">
                  <TableHead>
                    <TableRow>
                      <TableCell>API</TableCell>
                      <TableCell>Files</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(androidAPI).map(([api, details]) => (
                      <TableRow key={api}>
                        <TableCell>{details.metadata.description}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => toggleRow(api)}
                          >
                            {expandedRows[api] ? 'Hide Files' : 'Show Files'}
                          </Button>
                          <Collapse in={expandedRows[api]} timeout="auto" unmountOnExit>
                           <Typography variant="body2" component="div">
                            <ul>
                            {Object.entries(details.files).map(([file, line]) => (
                              <li key={file}>
                                {file} - Lines: {line}
                              </li>
                              ))}
                              </ul>
                            </Typography>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'browsable' && (
        <Box width={'100%'} mt={2}>
          {/* Browsable Activities */}
          <Card sx={{width:'100%'}} id="browsable">
            <CardContent>
              <Typography variant="h6"><i className="fas fa-clone" /> Browsable Activities</Typography>
              <TableContainer component={Paper}>
                <Table aria-label="browsable activities table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Activity</TableCell>
                      <TableCell>Intent</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {browsableActivities.length > 0 ? (
              browsableActivities.map((activity, index) => (
                <TableRow key={index}>
                  <TableCell>{activity.activity}</TableCell>
                  <TableCell>
                    {activity.intent && (
                      <Box>
                        {activity.intent.schemes && (
                          <Typography><strong>Schemes:</strong> {activity.intent.schemes.join(', ')}</Typography>
                        )}
                        {activity.intent.hosts && (
                          <Typography><strong>Hosts:</strong> {activity.intent.hosts.join(', ')}</Typography>
                        )}
                        {activity.intent.ports && (
                          <Typography><strong>Ports:</strong> {activity.intent.ports.join(', ')}</Typography>
                        )}
                        {activity.intent.mimeTypes && (
                          <Typography><strong>Mime Types:</strong> {activity.intent.mimeTypes.join(', ')}</Typography>
                        )}
                        {activity.intent.paths && (
                          <Typography><strong>Paths:</strong> {activity.intent.paths.join(', ')}</Typography>
                        )}
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  No data available in Table
                </TableCell>
              </TableRow>
            )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'network_analysis' && (
        <Box width={'100%'} mt={2}>
          {/* Network Security */}
          <Card sx={{width:'100%'}} id="network_analysis">
            <CardContent>
              <Typography variant="h6"><i className="fas fa-lock" /> Network Security</Typography>
              <Grid2 container spacing={2} my={2}>
                { networkSecurity && networkSecurity.summary && Object.entries(networkSecurity.summary).map(([key, value], index) => (
                  <Grid2 item="true" xs={6} sm={3} key={index}>
                    <Typography variant="subtitle1" color={key}>
                      {key.toUpperCase()}
                    </Typography>
                    <Typography variant="h5">{value}</Typography>
                  </Grid2>
                ))}
              </Grid2>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>NO</TableCell>
                      <TableCell>SCOPE</TableCell>
                      <TableCell>SEVERITY</TableCell>
                      <TableCell>DESCRIPTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { networkSecurity && networkSecurity.findings ? (
                        networkSecurity.findings.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {item.scope.map((url, i) => (
                            <Typography key={i}>{url}</Typography>
                          ))}
                        </TableCell>
                        <TableCell>{renderSeverityBadge(item.severity)}</TableCell>
                        <TableCell>{item.description}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No data available in Table
                      </TableCell>
                    </TableRow>
                  )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'certificate_analysis' && (
        <Box width={'100%'} mt={2}>
          {/* Certificate Analysis */}
          <Card sx={{width:'100%'}} id="certificate_analysis">
            <CardContent>
              <Typography variant="h6"><i className="fa fa-id-card" /> Certificate Analysis</Typography>
              <Grid2 container sx={{width:'100%', justifyContent:'center'}} spacing={2} my={2}>
                {Object.entries(certificateAnalysis.summary).map(([key, value], index) => (
                  <React.Fragment key={index}>
                  <Grid2 item="true" sx={{width:'20%'}}  xs={6} sm={3} key={index}>
                    <Typography variant="subtitle1" color={key}>
                      {key.toUpperCase()}
                    </Typography>
                    <Typography variant="h5">{value}</Typography>
                  </Grid2>
                  {index < Object.entries(certificateAnalysis.summary).length - 1 && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        marginLeft: '8px',
                        marginRight: '8px',
                        height: 'auto', // Adjusts to content
                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Divider color
                      }}
                    />
                  )}
                  </React.Fragment>
                ))}
              </Grid2>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>TITLE</TableCell>
                      <TableCell>SEVERITY</TableCell>
                      <TableCell>DESCRIPTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {certificateAnalysis.data.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{renderSeverityBadge(item.severity)}</TableCell>
                        <TableCell>{item.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'manifest_analysis' && (
        <Box width={'100%'} mt={2}>
          {/* Manifest Analysis */}
          <Card sx={{width:'100%'}} id="manifest_analysis">
            <CardContent>
              <Typography variant="h6"><i className="fas fa-search" /> Manifest Analysis</Typography>
              <Grid2 container sx={{width:'100%', justifyContent:'center'}} spacing={2} my={2}>
                {Object.entries(manifestAnalysis.summary).map(([key, value], index) => (
                  <React.Fragment key={index}>
                  <Grid2 item="true" sx={{width:'20%'}} xs={6} sm={3} key={index}>
                    <Typography variant="subtitle1" color={key}>
                      {key.toUpperCase()}
                    </Typography>
                    <Typography variant="h5">{value}</Typography>
                  </Grid2>
                  {index < Object.entries(manifestAnalysis.summary).length - 1 && (
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        marginLeft: '8px',
                        marginRight: '8px',
                        height: 'auto', // Adjusts to content
                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Divider color
                      }}
                    />
                  )}
                  </React.Fragment>
                ))}
              </Grid2>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>NO</TableCell>
                      <TableCell>ISSUE</TableCell>
                      <TableCell>SEVERITY</TableCell>
                      <TableCell>DESCRIPTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {manifestAnalysis.data.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.title) }} />
                        <TableCell>{renderSeverityBadge(item.severity)}</TableCell>
                        <TableCell>{item.description}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>  
    )}
    { selectedSection === 'code_analysis' && (
        <Box width={'100%'} mt={2}>
          {/* Code Analysis */}
          <Card sx={{width:'100%'}} id="code_analysis">
            <CardContent>
              <Typography variant="h6"><i className="fas fa-code" /> Code Analysis</Typography>
              <Grid2 container sx={{width:'100%', justifyContent:'center'}} spacing={2} my={2}>
                {Object.entries(codeAnalysis.summary).map(([key, value], index) => (
                  <React.Fragment key={index}>
                  <Grid2 item="true" sx={{width:'15%'}} xs={6} sm={2} key={index}>
                    <Typography variant="subtitle1" color={key}>
                      {key.toUpperCase()}
                    </Typography>
                    <Typography variant="h5">{value}</Typography>
                  </Grid2>
                  {index < Object.entries(codeAnalysis.summary).length - 1 && (
                    <Divider  
                      orientation="vertical"
                      flexItem
                      sx={{ 
                        marginLeft: '8px',
                        marginRight: '8px',
                        height: 'auto', // Adjusts to content
                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Divider color
                      }}  
                    />
                  )}
                  </React.Fragment>
                ))}
              </Grid2>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>NO</TableCell>
                      <TableCell>ISSUE</TableCell>
                      <TableCell>SEVERITY</TableCell>
                      <TableCell>STANDARDS</TableCell>
                      {/* <TableCell>FILES</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(codeAnalysis.data).map(([key,item], index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.metadata.description}</TableCell>
                        <TableCell>{renderSeverityBadge(item.metadata.severity)}</TableCell>
                        <TableCell>
                            <Typography key={'cvss'}>
                              <strong>CVSS V2:</strong> {item.metadata.cvss}
                            </Typography>
                            <Typography key={'cwe'}>
                              <strong>CWE:</strong> {item.metadata.cwe}
                            </Typography>
                            { item.metadata && item.metadata['owasp-mobile'] && (
                              <Typography key={'owasp-mobile'}>
                                <strong>OWASP Top 10:</strong> {item.metadata["owasp-mobile"]}
                              </Typography>
                            )}
                            { item.metadata && item.metadata['masvs'] && (
                                <Typography key={'owasp-masvs'}>
                                  <strong>OWASP MASVS:</strong> {item.metadata.masvs}
                                </Typography>
                            )}
                          {/* ))} */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'binary_analysis' && (
        <Box width={'100%'} mt={2}>
          {/* Shared Library Binary Analysis */}
          <Card sx={{width:'100%'}} id="binary_analysis">
            <CardContent>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                SHARED LIBRARY BINARY ANALYSIS
              </Typography>
              <TableContainer>
                <Table aria-label="binary analysis table">
                  <TableHead>
                    <TableRow>
                      {appType !== 'so' && (
                        <>
                          <TableCell>NO</TableCell>
                          <TableCell>SHARED OBJECT</TableCell>
                        </>
                      )}
                      <TableCell>NX</TableCell>
                      <TableCell>STACK CANARY</TableCell>
                      <TableCell>RELRO</TableCell>
                      <TableCell>RPATH</TableCell>
                      <TableCell>RUNPATH</TableCell>
                      <TableCell>FORTIFY</TableCell>
                      <TableCell>SYMBOLS STRIPPED</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sharedLibInfo && sharedLibInfo.length > 0 ? (
                      sharedLibInfo.map((so, index) => (
                        <TableRow key={index}>
                          {appType !== 'so' && (
                            <>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                {so.name} <br />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  href={`{url 'scan_library' checksum=${md5}}?library=${so.name}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="fa fa-search"></i> Analyze
                                </Button>
                              </TableCell>
                            </>
                          )}
                          <TableCell>
                            <strong>{so.nx.is_nx}</strong>
                            <br />
                            <Badge
                             sx ={{backgroundColor: so.nx.severity === 'high' ? 'red' : so.nx.severity === 'warning' ? 'yellow' : 'green'}}
                            >
                              {so.nx.severity}
                            </Badge>
                            <br />
                            {so.nx.description}
                          </TableCell>
                          <TableCell>
                            <strong>{so.stack_canary.has_canary}</strong>
                            <br />
                            <Badge
                              sx={{backgroundColor: so.stack_canary.severity === 'high' ? 'red' : so.stack_canary.severity === 'warning' ? 'yellow' : 'green'}}
                            >
                              {so.stack_canary.severity}
                            </Badge>
                            <br />
                            {so.stack_canary.description}
                          </TableCell>
                          <TableCell>
                            <strong>{so.relocation_readonly.relro}</strong>
                            <br />
                            <Badge
                            sx={{backgroundColor: so.relocation_readonly.severity === 'high' ? 'red' : so.relocation_readonly.severity === 'warning' ? 'yellow' : 'green'}}
                            >
                              {so.relocation_readonly.severity}
                            </Badge>
                            <br />
                            {so.relocation_readonly.description}
                          </TableCell>
                          <TableCell>
                            <strong>{so.rpath.rpath}</strong>
                            <br />
                            <Badge
                              sx={{backgroundColor: so.rpath.severity === 'high' ? 'red' : so.rpath.severity === 'warning' ? 'yellow' : 'green'}}
                            >
                              {so.rpath.severity}
                            </Badge>
                            <br />
                            {so.rpath.description}
                          </TableCell>
                          <TableCell>
                            <strong>{so.runpath.runpath}</strong>
                            <br />
                            <Badge
                              sx={{backgroundColor: so.runpath.severity === 'high' ? 'red' : so.runpath.severity === 'warning' ? 'yellow' : 'green'}}
                            >
                              {so.runpath.severity}
                            </Badge>
                            <br />
                            {so.runpath.description}
                          </TableCell>
                          <TableCell>
                            <strong>{so.fortify.is_fortified}</strong>
                            <br />
                            <Badge
                            sx={{backgroundColor: so.fortify.severity === 'high' ? 'red' : so.fortify.severity === 'warning' ? 'yellow' : 'green'}}
                            >
                              {so.fortify.severity}
                            </Badge>
                            <br />
                            {so.fortify.description}
                          </TableCell>
                          <TableCell>
                            <strong>{so.symbol.is_stripped}</strong>
                            <br />
                            <Badge
                            sx={{backgroundColor: so.symbol.severity === 'high' ? 'red' : so.symbol.severity === 'warning' ? 'yellow' : 'green'}}
                            >
                              {so.symbol.severity}
                            </Badge>
                            <br />
                            {so.symbol.description}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align='center' colSpan={8}>No Shared Objects found.</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'niap_analysis' && (  
        <Box width={'100%'} mt={2}>
          {/* NIAP Analysis */}
        <Card sx={{width:'100%'}} id="niap_analysis">
            <CardContent>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                NIAP ANALYSIS v1.3
              </Typography>
              <TableContainer>
                <Table aria-label="niap analysis table">
                  <TableHead>
                    <TableRow>
                      <TableCell>NO</TableCell>
                      <TableCell>IDENTIFIER</TableCell>
                      <TableCell>REQUIREMENT</TableCell>
                      <TableCell>FEATURE</TableCell>
                      <TableCell>DESCRIPTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { niapAnalysis && niapAnalysis.length > 0 ? (
                    Object.entries(niapAnalysis).map(([identifier, details], index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <Badge
                            sx={{backgroundColor: details.choice.includes('does not') ? 'yellow' : details.choice.includes('not in accordance') ? 'red' : 'green'}}
                          >
                            {identifier}
                          </Badge>
                        </TableCell>
                        <TableCell>{details.class}</TableCell>
                        <TableCell>{details.description}</TableCell>
                        <TableCell>{details.choice}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No data available in Table
                      </TableCell>
                    </TableRow>
                  )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'file_analysis' && (
        <Box width={'100%'} mt={2}>
          {/* File Analysis */}
        <Card sx={{width:'100%'}} id="file_analysis">
            <CardContent>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                FILE ANALYSIS
              </Typography>
              <TableContainer>
                <Table aria-label="file analysis table">
                  <TableHead>
                    <TableRow>
                      <TableCell>NO</TableCell>
                      <TableCell>ISSUE</TableCell>
                      <TableCell>FILES</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { fileAnalysis && fileAnalysis.length > 0 ? (
                    fileAnalysis.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.finding}</TableCell>
                        <TableCell>{item.files.join(', ')}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No data available in Table
                      </TableCell>
                    </TableRow>
                  )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'firebase_analysis' && (
        <Box width={'100%'} mt={2}>
          {/* FireBase Analysis */}
          <Card sx={{width:'100%'}} id="firebase_analysis">
            <CardContent>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                FIREBASE ANALYSIS
              </Typography>
              <TableContainer>
                <Table aria-label="firebase analysis table">
                  <TableHead>
                    <TableRow>
                      <TableCell>TITLE</TableCell>
                      <TableCell>SEVERITY</TableCell>
                      <TableCell>DESCRIPTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { firebaseAnalysis && firebaseAnalysis.length > 0 ? (
                    firebaseAnalysis.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{item.severity}</TableCell>
                        <TableCell>{item.description}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No data available in Table
                      </TableCell>
                    </TableRow>
                  )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'apkid_analysis' && (
        <Box width={'100%'} mt={2}>
          {/* APKiD Analysis */}
        <Card sx={{width:'100%'}} id="apkid_analysis">
            <CardContent>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                APKiD ANALYSIS
              </Typography>
              <TableContainer>
                <Table aria-label="apkid analysis table">
                  <TableHead>
                    <TableRow>
                      <TableCell>DEX</TableCell>
                      <TableCell>DETECTIONS</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { apkIdAnalysis && apkIdAnalysis.length > 0 ? (
                    apkIdAnalysis.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>{data['dex']}</TableCell>
                        <TableCell>
                          <Table>
                            <TableBody>
                              {data['detections'].map((detail, idens) => (
                                <TableRow key={detail['finding']}>
                                  <TableCell>
                                    <Badge
                                      sx={{backgroundColor: detail === "packer" ? 'red' : 'yellow'}}
                                    >
                                      {detail['finding']}
                                    </Badge>
                                  </TableCell>
                                  <TableCell>
                                    {detail['details'].map((value, idx) => (
                                      <Typography key={idx}>{value}</Typography>
                                    ))}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        No data available in Table
                      </TableCell>
                    </TableRow>
                  )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'abused_perm' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="abused_perm">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                <i className="fa fa-braille"></i> ABUSED PERMISSIONS
              </Typography>
              {permissionAnalysis && (
                <div>
                  <div>
                    <Typography variant="subtitle1">Top Malware Permissions</Typography>
                    <LinearProgress
                      variant="determinate"
                      value={(permissionAnalysis.top_malware_permissions.length / permissionAnalysis.total_malware_permissions) * 100}
                      color="error"
                    />
                    <Typography>{permissionAnalysis.top_malware_permissions.join(', ')}</Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle1">Other Common Permissions</Typography>
                    <LinearProgress
                      variant="determinate"
                      value={(permissionAnalysis.other_abused_permissions.length / permissionAnalysis.total_other_permissions) * 100}
                      color="warning"
                    />
                    <Typography>{permissionAnalysis.other_abused_permissions.join(', ')}</Typography>
                  </div>
                  <hr />
                  <Typography variant="body2">
                    <strong>Malware Permissions</strong> are the top permissions that are widely abused by known malware.
                    <br />
                    <strong>Other Common Permissions</strong> are permissions that are commonly abused by known malware.
                  </Typography>
                </div>
              )}
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'server_analysis' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="server_analysis">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                <MapIcon /> SERVER LOCATIONS
              </Typography>
              <MapWithLocations serversInfo={domainAnalysis} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                     /

              {/* Table with OFAC Domains */}
              {domainAnalysis && (
                <TableContainer>
                  <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    This app may communicate with the following OFAC sanctioned list of countries.
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>DOMAIN</TableCell>
                        <TableCell>COUNTRY/REGION</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(domainAnalysis).some(([_, details]) => details.ofac) ? (
                        Object.entries(domainAnalysis)
                          .filter(([_, details]) => details.ofac)
                          .map(([domain, details]) => (
                            <TableRow key={domain}>
                              <TableCell>{domain}</TableCell>
                              <TableCell>
                                <p><strong>IP: </strong>{details.geolocation.ip}</p>
                                <p><strong>Country: </strong>{details.geolocation.country_long}</p>
                                <p><strong>Region: </strong>{details.geolocation.region}</p>
                                <p><strong>City: </strong>{details.geolocation.city}</p>
                              </TableCell>
                            </TableRow>
                          ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            No OFAC domains found.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'domain_analysis' && (
        <Box width={'100%'} mt={2}>
        <Card sx={{width:'100%'}} id="domain_analysis">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                <MapIcon /> DOMAIN MALWARE CHECK
              </Typography>
              {/* Table with ALL Domains */}
              {domainAnalysis && (
                <TableContainer>
                  <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    This app may communicate with the following OFAC sanctioned list of countries.
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>DOMAIN</TableCell>
                        <TableCell>STATUS</TableCell>
                        <TableCell>GEOLOCATION</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(domainAnalysis).map(([domain, details]) =>
                          <TableRow key={domain}>
                            <TableCell>{domain}</TableCell>
                            {/* <TableCell>{details.bad === 'yes' ? 'malware' : 'ok' }</TableCell> */}
                            <TableCell>
                              <Chip
                                label={details.bad === 'yes' ? 'Malware' : 'OK'}
                                color={details.bad === 'yes' ? 'error' : 'success'}
                                sx={{ backgroundColor: details.bad === 'yes' ? 'red' : 'green', color: 'white' }}
                              />
                            </TableCell>
                            <TableCell>
                              <div><strong>IP: </strong>{details.geolocation.ip}</div>
                              <div><strong>Country: </strong>{details.geolocation.country_long}</div>
                              <div><strong>Region: </strong>{details.geolocation.region}</div>
                              <div><strong>City: </strong>{details.geolocation.city}</div>
                              <div><strong>Latitude: </strong>{details.geolocation.latitude}</div>
                              <div><strong>Longitude: </strong>{details.geolocation.longitude}</div>
                            </TableCell>
                          </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'urls' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="urls">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                <StorageIcon /> URLs
              </Typography>
              <div>
                {urlsAnalysis && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>URL</TableCell>
                          <TableCell>FILE</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(urlsAnalysis).map(([domain, details]) => (
                          <TableRow key={domain}>
                            <TableCell>
                            {details.urls.map((url, index) => (
                              <div key={index}>{url}</div>
                            ))}
                            </TableCell>
                            <TableCell>{details.path}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'emails' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="emails">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                <EmailIcon /> EMAILS
              </Typography> 
              {emailsAnalysis.length > 0 ? (
                <Table sx={{ minWidth: 650 }} aria-label="email table">
                  <TableHead>
                    <TableRow>
                      <TableCell>EMAIL</TableCell>
                      <TableCell>FILE</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {emailsAnalysis.map((email, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {email.emails.map((e, idx) => (
                            <div key={idx}>{e}</div>
                          ))}
                        </TableCell>
                        <TableCell>
                          <Link href={`/view_source?file=${email.path}&md5=${email.md5}&type=${email.appType}`} target="_blank">
                            {email.path}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography>No emails found.</Typography>
              )}
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'trackers' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="trackers">
            <CardContent>
            <Typography variant="h6" color="primary" gutterBottom>
                <UserSecretIcon /> Trackers
            </Typography> 
            {trackersAnalysis.length > 0 ? (
              <Table sx={{ minWidth: 650 }} aria-label="tracker table">
                <TableHead>
                  <TableRow>
                    <TableCell>TRACKER NAME</TableCell>
                    <TableCell>CATEGORIES</TableCell>
                    <TableCell>URL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trackersAnalysis.map((tracker, index) => (
                    <TableRow key={index}>
                      <TableCell>{tracker.name}</TableCell>
                      <TableCell>{tracker.categories}</TableCell>
                      <TableCell>
                        <Link href={tracker.url} target="_blank" rel="noopener noreferrer">
                          {tracker.url}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography>No trackers found.</Typography>
            )}
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'secrets' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="secrets">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                <KeyIcon /> POSSIBLE HARDCODED SECRETS
              </Typography> 
              {/* <ListComponent list={secretsAnalysis} type='items' limit="10"/> */}
              <List>
                {secretsAnalysis.map((secret, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={`${secret['key']} : ${secret['value']}`} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'strings' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="strings">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                <FontDownloadIcon /> STRINGS
              </Typography>
              <Typography variant="subtitle1">From APK Resource</Typography>
              <ListComponent list={stringsAnalysis.strings.strings_apk_res} type='items' limit="10"/>
              <Typography variant="subtitle1">From Code</Typography>
              <ListComponent list={stringsAnalysis.strings.strings_code} type='items' limit="10"/>
              <Typography variant="subtitle1">From Shared Objects</Typography>
              {stringsAnalysis.strings.strings_so.map((so, index) => (
                <div key={index}>
                  <Typography variant="body1"><strong>{so.name}</strong></Typography>
                  <List>
                  </List>
                  <Divider />
                </div>
              ))}
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'activities' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="activities">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                <LanguageIcon /> ACTIVITIES
              </Typography>
              <ListComponent list={activitiesAnalysis} type='items' limit="10"/>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'services' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="services">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                <SettingsIcon /> SERVICES
              </Typography>
              <ListComponent list={servicesAnalysis} type='items' limit="10"/>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'receivers' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="recievers">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                <AssistiveListeningSystemsIcon /> RECIEVERS
              </Typography>
              <ListComponent list={receiversAnalysis} type='items' limit="10"/>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'providers' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="providers">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                <StorageIcon /> PROVIDERS
              </Typography>
              <ListComponent list={providersAnalysis} type='items' limit="10"/>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'libraries' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="libraries">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                <StorageIcon /> LIBRARIES
              </Typography>
              <ListComponent list={librariesAnalysis} type='items' limit="10"/>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'files' && (
        <Box width={'100%'} mt={2}>
          <Card sx={{width:'100%'}} id="files">
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                <FileCopyIcon /> FILES
              </Typography>
              <ListComponent list={filesAnalysis} type='items' limit="10"/>
            </CardContent>
          </Card>
        </Box>
    )}
    { selectedSection === 'scorecard' && (
       <Box width="100%" mt={2}>
       <Card sx={{ width: '100%' }} id="scorecard">
         <CardContent>
            <Typography variant="h6" color="primary" gutterBottom>
             SCORECARD
            </Typography>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="scorecard tabs" variant="fullWidth">
              <Tab label="Ratings" />
              <Tab label="Findings" />
            </Tabs>
           <Box sx={{ padding: 2 }}>
            {selectedTab === 0 && (
             <Grid2 container spacing={2}>
               {/* Security Score Card */}
               <Grid2 item xs={12} sm={6} md={3}>
                 <Card sx={{ height: '100%' }}>
                  <CardHeader
                      title="Security Score"
                      titleTypographyProps={{
                        variant: 'h6',
                        sx: { textAlign: 'left' }, // Align the title to the left
                      }}
                      sx={{ paddingBottom: 0 }} // Remove padding bottom to fit the divider
                    />
                   <Divider sx={{ marginBottom: 2 }} />
                   <CardContent>
                     <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                       <Chart options={securityScoreOptions} series={[securityScore]} type="radialBar" />
                     </Box>
                   </CardContent>
                 </Card>
               </Grid2>
 
               {/* Risk Rating Card */}
               <Grid2 item xs={12} sm={6} md={3}>
                 <Card sx={{ height: '100%' }}>
                   <CardHeader
                     title="Risk Rating"
                     titleTypographyProps={{
                       variant: 'h6',
                       sx: { textAlign: 'left' }, // Align the title to the left
                     }}
                     sx={{ paddingBottom: 0 }} // Remove padding bottom to fit the divider
                   />
                   <Divider sx={{ marginBottom: 2 }} />
                   <CardContent>
                     <Chart options={riskOptions} series={riskOptions.series} type="radialBar" height={280} />
                     <Box
                       sx={{
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         position: 'relative',
                         height: '100%',
                         top: '50%',
                         transform: 'translateY(-50%)',
                       }}
                     >
                       <Typography variant="h5" color="textPrimary" sx={{ marginRight: 1 }}>
                         Grade:
                       </Typography>
                       <Typography variant="h5" color="textPrimary">
                         {scoreCardData.grade}
                       </Typography>
                     </Box>
                   </CardContent>
                 </Card>
               </Grid2>
 
               {/* Severity Distribution Card */}
               <Grid2 item xs={12} sm={6} md={3}>
                 <Card sx={{ height: '100%' }}>
                   <CardHeader
                     title="Severity Distribution"
                     titleTypographyProps={{
                       variant: 'h6',
                       sx: { textAlign: 'left' },
                     }}
                     sx={{ paddingBottom: 0 }}
                   />
                   <Divider sx={{ marginBottom: 2 }} />
                   <CardContent>
                     <Chart options={severityChartOptions} series={severityChartData} type="pie" height={150} />
                   </CardContent>
                 </Card>
               </Grid2>
 
               {/* Privacy Risk Card */}
              <Grid2 item xs={12} sm={6} md={3}>
                <Card sx={{ height: '100%' }}>
                  <CardHeader
                    title="Privacy Risk"
                    titleTypographyProps={{
                      variant: 'h6',
                      sx: { textAlign: 'left' },
                    }}
                    sx={{ paddingBottom: 0 }}
                  />
                  <Divider sx={{ marginBottom: 2 }} />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      {/* Show the tracker number */}
                      <Typography
                        variant="h2"
                        color="textPrimary"
                        sx={{ fontWeight: 'bold' }}
                        mb={5}
                      >
                        {scoreCardData.trackers}
                      </Typography>

                      {/* Label for the number */}
                      <Typography variant="body" color="textSecondary" sx={{ marginTop: 1 }}>
                        User/Device Trackers
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid2>
            </Grid2>
            )}
            {selectedTab === 1 && (
              // Findings Tab: Show findings grouped by severity
              <Box sx={{ width: "100%", mt: 3 }}>
              {Object.keys(groupedFindings).
              filter(
                (severity) => severity !== "security_score" && severity !== "total_trackers"
              ).map((severity) => (
                <Accordion
                  key={severity}
                  sx={{
                    mb: 2, // Add spacing between accordions
                    borderRadius: 2, // Rounded corners
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for a clean look
                    border: `1px solid ${severityColors[severity] || "#ccc"}`, // Border color based on severity
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      // backgroundColor: severityColors[severity] || "#f5f5f5", // Background color for header
                      backgroundColor: '#fff',
                      // color: "#fff", // White text for header
                      borderRadius: "8px 8px 0 0", // Round the top corners
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold",  color: severityColors[severity] || "#000" }}>
                      {severity.charAt(0).toUpperCase() + severity.slice(1)} (
                      {severityCount[severity]})
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {groupedFindings[severity]?.length > 0 ? (
                      <List>
                        {groupedFindings[severity].map((finding, index) => (
                          <ListItem key={index} sx={{ mb: 1 }}>
                            <ListItemText
                              primary={finding.title}
                              secondary={finding.description}
                              sx={{
                                backgroundColor: "#fafafa",
                                padding: 2,
                                borderRadius: 2,
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography>No findings in this category.</Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
//               <Box>
//   {Object.entries(severityCount).map(([severity, count]) => (
//     <Accordion key={severity}>
//       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//         <Typography variant="h6">
//           {severity.charAt(0).toUpperCase() + severity.slice(1)} ({count})
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails>
//         <List>
//           {groupedFindings[severity]?.map((finding, index) => (
//             <ListItem key={index}>
//               <ListItemText
//                 primary={finding.title}
//                 secondary={
//                   <>
//                     <Typography variant="body2" color="textSecondary">
//                       {finding.description}
//                     </Typography>
//                     {finding.section && (
//                       <Typography variant="caption" color="textPrimary">
//                         Section: {finding.section}
//                       </Typography>
//                     )}
//                   </>
//                 }
//               />
//             </ListItem>
//           ))}
//         </List>
//       </AccordionDetails>
//     </Accordion>
//   ))}
// </Box>
              // <Box>
              //   {['high', 'medium', 'info', 'secure', 'hotspot'].map((severity) => (
              //     <Accordion key={severity}>
              //       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              //         <Typography variant="h6">{severity.charAt(0).toUpperCase() + severity.slice(1)} ({severityCount[severity]})</Typography>
              //       </AccordionSummary>
              //       <AccordionDetails>
              //         <List>
              //           {groupedFindings[severity]?.map((finding, index) => (
              //             <ListItem key={index}>
              //               <ListItemText primary={finding.description} />
              //             </ListItem>
              //           ))}
              //         </List>
              //       </AccordionDetails>
              //     </Accordion>
              //   ))}
              // </Box>
            )}
          </Box>
         </CardContent>
       </Card>
     </Box>
      
    )}
      </Box>
    </Box>
    )
    </>
  );
};

export default StaticAnalysisDashboard;


