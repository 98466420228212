import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');

  if (!token) {
    return <Navigate to="/" />;
  }

  const isTokenExpired = () => {
    try {
      const token = localStorage.getItem('token');
      const expiryTime = localStorage.getItem('session_expiry');
      if (token && expiryTime) {
        const expiryTimestamp = new Date(expiryTime).getTime();
        if (Date.now() < expiryTimestamp) {
          return false
        } else {
          localStorage.removeItem('token')
          localStorage.removeItem('session_expiry')
          return true
        }
      }
    } catch {
      return true;
    }
  };

  return isTokenExpired() ? <Navigate to="/" /> : children;
};

export default PrivateRoute;
